@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');


html,
body,
:root{
  height: 100%;
  background-color: rgb(255, 255, 255);
  color-scheme: dark;
}


  @keyframes plane {
    0% {
      transform: translateX(360%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .animate-plane {
    animation-name: plane;
    animation-duration: 20s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes house {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(90%);
    }
  }
  
  .animate-house {
    animation-name: house;
    animation-duration: 40s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }


  .rounded-right {
    border-radius: 0 1rem 1rem 0 !important;
  }


  
#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--swiper-theme-color);
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

.bg-custom-gradient {
  background: linear-gradient(136deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.06) 41.15%, rgba(255, 255, 255, 0.06) 63.02%, rgba(255, 255, 255, 0.24) 100%);
}